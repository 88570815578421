<template>
  <CustomModalWithScroll
    v-show="isShowModal"
    :visible="isShowModal"
    :empty="true"
    class="modal-address"
    @close="closeModalHandler"
    @closeOverlay="closeModalHandler"
  >
    <template #content>
      <div v-if="mqIsMobile" class="modal-address__header font">
        <div class="modal-address__right-header-wrapper">
          <div class="modal-address__right-header-hl font_title-m font_bold">
            {{ title }}
          </div>
          <svg-icon
            class="modal-address__right-header-icon"
            name="close"
            width="15"
            height="15"
            @click="closeModalHandler"
          />
        </div>
        <div class="modal-address__header-controls">
          <Button
            v-for="item in switchList"
            :key="item.id"
            theme="simple"
            size="sm"
            class="modal-address__header-controls-item"
            :class="{'modal-address__header-controls-item_active' : item.id === selectedSwitch}"
            @click="onSwitchClick(item.id)"
          >
            <template #iconLeft>
              <svg-icon
                :name="item.icon"
                width="24"
                height="24"
              />
            </template>
            {{ item.name }}
          </Button>
        </div>
      </div>

      <client-only>
        <div v-show="!mqIsMobile || isMap" class="modal-address__map">
          <slot name="map" />
          <YandexMapSmall
            :zoom="zoom"
            :settings="mapSettings"
            :coords="coords"
            :controls="mapControls"
            :options="computedOptions"
            :cluster-options="clusterOptions"
            @click="onMapClick"
            @map-was-initialized="mapInitialized"
          >
            <template v-if="clusterCoords.length">
              <YmapMarkerSmall
                v-for="point in clusterCoords"
                :key="`${point.id}-${point.methodID}`"
                :marker-id="`marker-${point.id}`"
                :coords="point.map.coords"
                :icon="selectedMark === point.id ? selectedMarkerIcon : markerIcon"
                cluster-name="default-cluster"
              />
            </template>
            <YmapMarkerSmall
              v-else-if="coords"
              marker-id="marker-small"
              :coords="coords"
              :icon="markerIcon"
              :options="{ draggable: true }"
              @dragend="onDragMapMarker"
            />
          </YandexMapSmall>
        </div>
      </client-only>
      <div v-show="!mqIsMobile || !isMap" class="modal-address__right">
        <div class="modal-address__right-header font">
          <div class="modal-address__right-header-wrapper">
            <div class="modal-address__right-header-hl font_title-m font_bold">
              {{ title }}
            </div>
            <span v-if="subtitle" class="font_sm font_grey">{{ subtitle }}</span>
          </div>

          <svg-icon
            class="modal-address__right-header-icon"
            name="close"
            width="15"
            height="15"
            @click="closeModalHandler"
          />
        </div>
        <slot />
      </div>
    </template>
  </CustomModalWithScroll>
</template>
<script>
import { yandexMap as YandexMapSmall, ymapMarker as YmapMarkerSmall } from 'vue-yandex-maps';
import pin from '@images/location/map-pin.svg';
import selectedPin from '@images/location/map-pin-selected.svg';

import CustomModalWithScroll from '~/components/elements/CustomModalWithScroll';
import Button from '~/components/elements/Button';
import {yaMapOptions} from '~/plugins/settings';

export default {
  name: 'ModalAddressWrapper',
  components: {
    Button,
    CustomModalWithScroll,
    YandexMapSmall,
    YmapMarkerSmall,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    rightName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    zoom: {
      type: Number,
      default: 13,
    },
    mapSettings: {
      type: Object,
      default: () => {},
    },
    mapOptions: {
      type: Object,
      default: () => {},
    },
    mapControls: {
      type: Array,
      default: () => [],
    },
    clusterOptions: {
      type: Object,
      default: () => {},
    },
    coords: {
      type: Array,
      default: () => [],
    },
    clusterCoords: {
      type: Array,
      default: () => [],
    },
    selectedMark: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      switchList: [
        { id: 'map', icon: 'map',  name: 'Карта' },
        { id: 'right', icon: 'list', name: this.rightName },
      ],
      selectedSwitch: 'right',
      yaMapOptions,
      markerIcon: {
        layout: 'default#image',
        imageHref: pin,
        imageSize: [32, 40],
        imageOffset: [-16, -20],
      },
      selectedMarkerIcon: {
        layout: 'default#image',
        imageHref: selectedPin,
        imageSize: [32, 40],
        imageOffset: [-16, -20],
      },
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isMap() {
      return this.selectedSwitch === 'map';
    },
    computedOptions() {
      return {
        ...this.yaMapOptions,
        ...this.mapOptions,
      }
    },
  },
  watch: {
    selectedMark() {
      this.onSwitchClick('right')
    },
  },
  methods: {
    closeModalHandler() {
      this.$emit('close');
    },
    onSwitchClick(id) {
      this.selectedSwitch = id
    },
    onMapClick(e) {
      this.$emit('mapClick', e);
    },
    mapInitialized(e) {
      this.$emit('mapInit', e);
    },
    onDragMapMarker(e) {
      this.$emit('mapDragMarker', e);
    },
  },
}
</script>
